import React from "react";

const ItemBox = (props) => {
  return (
    <div
      style={{
        backgroundColor: "#ffffff",
        width: "100%",
        boxShadow: " 0px 0px 8px #00000029",
        borderRadius: "16px",
        marginTop: props.marginTop,
        padding: props.padding,
        height: props.height,
      }}
    >
      {props.children}
    </div>
  );
};

export default ItemBox;
