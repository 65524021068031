import { createTheme } from "@material-ui/core";

const theme = createTheme({
  direction: "rtl",
  palette: {
    primary: {
      main: "#0731e0",
    },
    secondary: {
      light: "#0066ff",
      main: "#0044ff",
      contrastText: "#ffcc00",
    },
  },
  typography: {
    button: {
      color: "var(--white-text-color)",
      backgroundColor: "var(--unnamed-color-161557)",
    },
  },
});

export default theme;
