import React, { useLayoutEffect } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { navigationActions } from "../../Store/Navigation";

const useGetRoute = () => {
  const params = useLocation();
  const dispatch = useDispatch();
  useLayoutEffect(() => {
    const idExists = params.pathname.slice(1).includes("/");
    const idIndex = params.pathname.lastIndexOf("/");
    if (idExists) {
      dispatch(
        navigationActions.setRefreshRoute({
          id: params.pathname.slice(idIndex + 1),
          route: params.pathname.slice(1, idIndex),
        })
      );
    } else {
      dispatch(
        navigationActions.setRefreshRoute({
          id: null,
          route: params.pathname.slice(1),
        })
      );
    }
  }, [params.pathname]);
};

export default useGetRoute;
