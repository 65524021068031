import { createSlice } from "@reduxjs/toolkit";

const initialPlatformState = {
  score: null,
  isLoading: false,
  parts: [
    { name: "battery", score: null },
    { name: "external_network", score: null },
    { name: "left_lock", score: null },
    { name: "generator", score: null },
    { name: "right_lock", score: null },
  ],
  state: {
    genV: 0,
    batteryV: 0,
    angel: 0,
    pitch: 0,
    roll: 0,
  },
};

const platformSlice = createSlice({
  name: "platform",
  initialState: initialPlatformState,
  reducers: {
    setScore(state, action) {
      state.score = action.payload;
    },
    setParts(state, action) {
      state.parts = action.payload;
    },
    setState(state, action) {
      state.state = action.payload;

      state.parts[0].states[3] = {
        voltageExtNetwork: `${action.payload.batteryV}v`,
      };
      state.parts[3].states = [
        { voltageExtNetwork: `${action.payload.genV}v` },
      ];
    },
    setLoading(state, action) {
      state.isLoading = action.payload;
    },
  },
});

export const platformActions = platformSlice.actions;

export default platformSlice.reducer;
