import React, { useState, useEffect, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import { NavLink, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import cookie from "js-cookie";
import TopBar from "../TopBar/TopBar";
import AppContext from "../../../Context/AppContext";
import useGetRoute from "../../../Hooks/Route";
import Logout from "../Logout/Logout";

import {
  CssBaseline,
  Divider,
  Drawer,
  ListItem,
  ListItemIcon,
  Toolbar,
  makeStyles,
} from "@material-ui/core";

const drawerWidth = 238;
const topBarHeight = 93;

const useSideBarStyles = makeStyles({
  item: {
    padding: "0px",
    position: "relative",
    "& a": {
      textDecoration: "none",
      color: "inherit",
    },
  },
  notActive: {
    width: "100%",
    padding: "10px",
    display: "flex",
  },
  divider: {
    position: "absolute",
    top: 0,
    left: 0,
    height: "100%",
  },
  active: {
    width: "100%",
    padding: "10px",
    display: "flex",
    backgroundColor: "var(--no-info)",
    color: "red",
    "& img": {
      filter:
        "brightness(0) saturate(100%) invert(17%) sepia(57%) saturate(5877%) hue-rotate(232deg) brightness(83%) contrast(115%)",
    },
    "& p": {
      color: "var(--unnamed-color-161557)",
    },
    "& hr": {
      border: "1px solid var(--unnamed-color-161557)",
    },
  },
  appbar: {
    paddingLeft: "160px",
    paddingRight: "160px",
    backgroundColor: "var(--bg-color)",
  },
  logo: {
    backgroundColor: "var(--unnamed-color-161557)",
    height: topBarHeight,
    marginBottom: "23px",
  },
  drawerPaper: {
    width: drawerWidth,
  },
  mainContent: {
    paddingLeft: drawerWidth,
  },
});

const SideBar = (props) => {
  const { t } = useTranslation();
  const { user } = useContext(AppContext);
  useGetRoute();

  const navOptions = useSelector((state) => state.navigation.navOptions);
  const language = useSelector((state) => state.settings.language);
  const [dir, setDir] = useState("left");

  useEffect(() => {
    const code = cookie.get("i18next");
    if (code === "he") {
      setDir("left");
    } else {
      setDir("right");
    }
  }, [language]);

  const classes = useSideBarStyles();

  const drawer = (
    <>
      <Toolbar className={classes.logo}>
        <img
          src="/assets/Icons/Navigition/SideBar/logo.svg"
          alt="Moblie Group Logo"
        />
      </Toolbar>
      <Divider />
      <div
        style={{
          position: "relative",
        }}
      >
        {navOptions.map((element) => (
          <div key={element.route} className={classes.optionTxt}>
            <ListItem button className={classes.item}>
              <NavLink
                className={(navData) =>
                  navData.isActive ? classes.active : classes.notActive
                }
                to={"/" + element.route}
              >
                <ListItemIcon>
                  <img src={element.icon} alt={t(element.route)} width="24px" />
                </ListItemIcon>
                <p className={classes.itemTxt}>{t(element.route)}</p>
                <Divider
                  className={classes.divider}
                  variant="fullWidth"
                  orientation="vertical"
                  flexItem
                />
              </NavLink>
            </ListItem>
          </div>
        ))}
        <Logout />
      </div>
      <Divider />
    </>
  );

  return (
    <div style={{ display: "flex", width: "100%", zIndex: "8" }}>
      {user !== null && (
        <>
          <TopBar />
          <CssBaseline />
          <Drawer variant="permanent" anchor={dir} open>
            {drawer}
          </Drawer>
        </>
      )}
      <>{props.children}</>
    </div>
  );
};

export default SideBar;
