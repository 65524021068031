import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import useStickyState from "../Hooks/LocalStorage";
import { SettingsContextProvider } from "./SettingsContext";

const AppContext = React.createContext({
  user: null,
  setUser: () => {},
  token: null,
  setToken: () => {},
  login: () => {},
  logout: () => {},
});

export const AppContextProvider = (props) => {
  const [user, setUser] = useStickyState(null, "user");
  const [token, setToken] = useStickyState(null, "token");
  const navigate = useNavigate();

  const logout = () => {
    setUser(null);
    localStorage.removeItem("user");
    setToken(null);
    localStorage.removeItem("token");

    navigate("/", { replace: true });
  };

  const login = (user, token) => {
    setUser(user);
    navigate(`/dashboard`, { replace: true });
  };

  return (
    <SettingsContextProvider>
      <AppContext.Provider
        value={{
          user,
          setUser: setUser,
          setUser: setUser,
          token,
          login: login,
          logout: logout,
        }}
      >
        {props.children}
      </AppContext.Provider>
    </SettingsContextProvider>
  );
};

export default AppContext;
