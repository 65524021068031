import React from "react";

import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  shimmerWrapper: {
    position: "absolute",
    top: " 0",
    left: "0",
    width: "100%",
    height: "100%",
    animation: `$loading 1000ms infinite`,
  },
  shimmer: {
    width: "50%",
    height: "100%",
    backgroundColor: "rgba(255,255,255,0.2)",
    transform: "skewX(-20deg)",
  },
  "@keyframes loading": {
    "0%": {
      transform: "translateX(-150%)",
    },
    "50%": {
      transform: "translateX(-50%)",
    },
    "100%": {
      transform: "translateX(150%)",
    },
  },
});

const Shimmer = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.shimmerWrapper}>
      <div className={classes.shimmer}></div>
    </div>
  );
};

export default Shimmer;
