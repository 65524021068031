import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import i18next from "i18next";
import cookie from "js-cookie";
import { settingsActions } from "../../../Store/Settings";
import { Typography } from "../../../UiKit";

import { Select, makeStyles, MenuItem } from "@material-ui/core";

const useStyles = makeStyles({
  optionWraper: {
    display: "flex",
    gap: "14px",
    zIndex: "100",
  },
});

const LanguageSelect = (props) => {
  const classes = useStyles();

  const dispatch = useDispatch();
  const language = useSelector((state) => state.settings.language);

  useEffect(() => {
    const code = cookie.get("i18next");
    if (code === "he") {
      dispatch(settingsActions.toggleLanguage({ code, dir: "rtl" }));
      document.body.setAttribute("dir", "rtl");
    } else {
      dispatch(settingsActions.toggleLanguage({ code, dir: "ltr" }));
      document.body.setAttribute("dir", "ltr");
    }
  }, []);

  const handleChange = (event) => {
    if (event.target.value === "he") {
      dispatch(
        settingsActions.toggleLanguage({ code: event.target.value, dir: "rtl" })
      );
      document.body.setAttribute("dir", "rtl");
    } else {
      dispatch(
        settingsActions.toggleLanguage({ code: event.target.value, dir: "ltr" })
      );
      document.body.setAttribute("dir", "ltr");
    }
    i18next.changeLanguage(event.target.value);
  };

  const languages = [
    {
      code: "he",
      name: "עברית",
      country_code: "🇮🇱",
      flag: "/assets/Icons/Settings/israel.svg",
      dir: "rtl",
    },
    {
      code: "en",
      name: "English",
      country_code: "gb",
      flag: "/assets/Icons/Settings/usa.svg",
      dir: "ltr",
    },
  ];

  return (
    <Select
      className={classes.select}
      labelId="demo-simple-select-label"
      id="demo-simple-select"
      onChange={handleChange}
      value={language.code}
    >
      {languages.map((lang) => (
        <MenuItem key={lang.country_code} value={lang.code}>
          <div className={classes.optionWraper}>
            <img width="20px" src={lang.flag}></img>
            <Typography>{lang.name}</Typography>
          </div>
        </MenuItem>
      ))}
    </Select>
  );
};

export default LanguageSelect;
