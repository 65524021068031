import React, { useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { NavLink, useLocation } from "react-router-dom";
import {
  colors,
  InputAdornment,
  makeStyles,
  TextField,
} from "@material-ui/core";
import {
  platformsActions,
  unitsActions,
  areasActions,
  unitActions,
  areaActions,
  eventsActions,
} from "../../../Store/actions";

const useStyles = makeStyles({
  underline: (props) => ({
    "&&&:before": {
      borderBottom: !props.variant && "none",
    },
    "&&:after": {
      borderBottom: !props.variant && "none",
    },
  }),
});

const Serach = (props) => {
  const currentRoute = useSelector((state) => state.navigation.currentRoute);
  const searchTextRef = useRef();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const classes = useStyles({ variant: props.variant });

  useEffect(() => {
    searchTextRef.current = "";
  }, [currentRoute.route]);

  const SearchHandler = (e) => {
    if (currentRoute.route === "platforms") {
      dispatch(platformsActions.SearchById(e.target.value));
    }
    if (currentRoute.route === "notifications") {
      dispatch(
        eventsActions.FilterByText({ value: e.target.value, key: props.id })
      );
    }
    if (currentRoute.route === "areas" && currentRoute.id) {
      dispatch(areaActions.SearchById(e.target.value));
    }
    if (currentRoute.route === "units" && currentRoute.id) {
      dispatch(unitActions.SearchById(e.target.value));
    }
    if (currentRoute.route === "units" && !currentRoute.id) {
      dispatch(unitsActions.SearchById(e.target.value));
    }
    if (currentRoute.route === "areas" && !currentRoute.id) {
      dispatch(areasActions.SearchById(e.target.value));
    }
  };

  return (
    <div
      style={{
        pointerEvents: "all",
      }}
    >
      <TextField
        variant={props.variant ? props.variant : "standard"}
        margin="normal"
        id="search"
        ref={searchTextRef}
        name="search"
        onChange={SearchHandler}
        placeholder={
          props.placeholder
            ? t(props.placeholder)
            : currentRoute.id
            ? t("platform")
            : t(currentRoute.route.slice(0, -1))
        }
        InputProps={
          props.variant
            ? {}
            : {
                startAdornment: (
                  <InputAdornment position="start">
                    <img
                      style={{ marginLeft: "10px", opacity: 0.6 }}
                      src="/assets/Icons/Navigition/TopBar/search.svg"
                      width="24px"
                    ></img>
                  </InputAdornment>
                ),
                classes,
              }
        }
      />
    </div>
  );
};

export default Serach;
