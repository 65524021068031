import initialTitles from "../../Constants/initialTitles";
import { createSlice } from "@reduxjs/toolkit";

const initialDashboardState = {
  titleOptions: initialTitles,
  statuses: [
    {
      name: "areasStatus",
      areasStatus: [
        { name: "high", value: 0 },
        { name: "mid", value: 0 },
        { name: "low", value: 0 },
      ],
      requets: "IronDome/Web/Areas/Status/get/All",
      title: {
        icon: "/assets/Icons/Navigition/SideBar/areas.svg",
        text: "area",
      },
    },
    {
      name: "unitsStatus",
      unitsStatus: [
        { name: "high", value: 0 },
        { name: "mid", value: 0 },
        { name: "low", value: 0 },
      ],
      requets: "IronDome/Web/Units/Status/get/All",
      title: {
        icon: "/assets/Icons/Navigition/SideBar/units.svg",
        text: "area",
      },
    },
    {
      name: "parts_status",
      parts_status: [],
      title: {},
    },
  ],
  dateTime: { dateStart: 1646085600000, dateEnd: 1648674000000 },
  area: {},
  unit: {},
};

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState: initialDashboardState,
  reducers: {
    setTitleOptionsNum(state, action) {
      state.titleOptions.map((titleOption) => {
        titleOption.number = action.payload[titleOption.serverKey];
      });
    },
    setUnit(state, action) {
      state.unit = action.payload;
    },
    setArea(state, action) {
      state.area = action.payload;
    },
    setUnitsStatus(state, action) {
      state.statuses[1].unitsStatus = action.payload;
    },
    setAreasStatus(state, action) {
      state.statuses[0].areasStatus = action.payload;
    },
    setPartsStatus(state, action) {
      state.statuses[2].parts_status = action.payload;
    },
    setDateTime(state, action) {
      state.dateTime[action.payload.name] = action.payload.value;
    },
  },
});

export const dashboardActions = dashboardSlice.actions;

export default dashboardSlice.reducer;
