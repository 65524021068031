import { createSlice } from "@reduxjs/toolkit";
import addIfNew from "../../Services/Arrays";

const initialAreasState = {
  areas: [],
  areasCopy: [],
  scoreOptions: [],
  scoreSelected: "All",
  dashboard: { filter: false, score: null },
};

const AreasSlice = createSlice({
  name: "areas",
  initialState: initialAreasState,
  reducers: {
    setDashboard(state, action) {
      state.dashboard.score = action.payload;
      state.dashboard.filter = true;
    },
    setAreas(state, action) {
      state.areas = action.payload;
      state.areasCopy = action.payload;
      action.payload.map((area) => {
        addIfNew(state.scoreOptions, area.ScoreTitle);
      });
    },
    SearchById(state, action) {
      state.areas = state.areasCopy.filter((area) => {
        let scoreFlag = true;
        if (area.AreaID.includes(action.payload)) {
          if (state.scoreSelected !== "All")
            scoreFlag = area.ScoreTitle === state.scoreSelected;
          return scoreFlag;
        }
      });
    },
    SearchByScore(state, action) {
      state.scoreSelected = action.payload;
      if (action.payload === "All") {
        state.areas = state.areasCopy;
      } else {
        state.areas = state.areasCopy.filter((area) => {
          return area.ScoreTitle === action.payload || action.payload === "All";
        });
      }
      state.dashboard.filter = false;
    },
    ResetOptions(state, action) {
      state.scoreSelected = "All";
      state.areas = state.areasCopy;
    },
  },
});

export const areasActions = AreasSlice.actions;

export default AreasSlice.reducer;
