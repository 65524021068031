import React from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { LanguageSelect } from "../../../Components/Settings";
import Serach from "../Search/Serach";

import { AppBar, makeStyles } from "@material-ui/core";

import { Typography, StatusSnackBar } from "../../../UiKit";

const drawerWidth = 238;
const topBarHeight = 93;

const useTopBarStyles = makeStyles({
  appbar: (props) => ({
    height: topBarHeight,
    width: "100%",
    paddingLeft: props.code === "he" ? "10px" : drawerWidth,
    paddingRight: props.code === "en" ? "10px" : drawerWidth,
    backgroundColor: "var(--bg-color)",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    gap: "var(--gap)",
    flexDirection: "row",
    color: "var(--unnamed-color-161557)",
  }),
  routeContiner: {
    display: "flex",
    alignItems: "center",
    gap: "var(--gap)",
  },
  currentRoute: {
    display: "flex",
    alignItems: "center",
    gap:"16px"
  },
  iconWarper: {
    backgroundColor: "var(--unnamed-color-161557)",
    padding: "8px",
    borderRadius: "10px",
    display: "flex",
  },
  routeImage: {
    filter:
      "invert(100%) sepia(100%) saturate(0%) hue-rotate(144deg) brightness(200%) contrast(104%)",
  },
});

const TopBar = (props) => {
  const { t } = useTranslation();
  const currentRoute = useSelector((state) => state.navigation.currentRoute);
  const currentStore = useSelector((state) => state[currentRoute.search]);
  const language = useSelector((state) => state.settings.language);
  const classes = useTopBarStyles({ code: language.code });

  return (
    <AppBar className={classes.appbar}>
      <div className={classes.routeContiner}>
        <div className={classes.currentRoute}>
          <div className={classes.iconWarper}>
            <img
              className={classes.routeImage}
              src={currentRoute.icon}
              alt={currentRoute.route}
              width="40px"
            />
          </div>
          &nbsp;&nbsp;
          <Typography
            className="title"
            style={{fontSize:"28px"}}
          >
            {currentRoute.id
              ? `${t(`one_${currentRoute.route}`)} - ${currentRoute.id}`
              : t(currentRoute.route)}
          </Typography>
        </div>
        {currentRoute.id && (
          <StatusSnackBar
            lvl={currentStore?.score}
            isLoading={currentStore?.isLoading}
          />
        )}
      </div>
      <div style={{ display: "flex" }}>
        {currentRoute.search && !currentRoute.id && (
          <Serach text={t(currentRoute.search)} />
        )}
        <LanguageSelect />
      </div>
    </AppBar>
  );
};

export default TopBar;
