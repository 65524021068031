import React from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core";
import { Typography, Skeleton } from "../..";

const useStyles = makeStyles({
  container: {
    width: "90%",
    height: "35px",
    padding: "4px, 8px",
    display: "flex",
    gap: "10px",
    alignItems: "center",
    borderRadius: "4px",
    justifyContent: "center",
    position: "relative",
  },
  overlay: {
    width: "100%",
    backgroundColor: "var(--part-status-bg-color)",
    borderRadius: "4px",
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    opacity: "0.1",
  },
  topic: {
    color: "var(--unnamed-color-240912)",
  },
});

const PartSnackBar = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const partIcon = () => {
    if (props.name === "Generator") {
      return "/assets/Icons/Platform/generator.svg";
    }
    if (props.name === "Batteries") {
      return "/assets/Icons/Platform/battery.svg";
    }
    if (props.name === "RightLock") {
      return "/assets/Icons/Platform/right_lock.svg";
    }
    if (props.name === "LeftLock") {
      return "/assets/Icons/Platform/left_lock.svg";
    }
    if (props.name === "ExternalNetwork") {
      return "/assets/Icons/Platform/external_network.svg";
    }
  };

  return (
    <div className={classes.container}>
      <div className={classes.overlay}></div>
      <img src={partIcon()} width="24" />
      <Typography variant="16" className={classes.topic}>
        {t(props.name)}
      </Typography>
    </div>
  );
};

export default PartSnackBar;
