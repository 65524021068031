const initialTitles = [
  {
    serverKey: "High",
    topic: "high_competency",
    isSelected: false,
    icon: "/assets/Icons/Dashboard/green.svg",
    number: "0",
    color: "var(--high)",
  },
  {
    serverKey: "Medium",
    topic: "mid_competency",
    isSelected: false,
    icon: "/assets/Icons/Dashboard/yellow.svg",
    number: "0",
    color: "var(--mid)",
  },
  {
    serverKey: "Low",
    topic: "low_competency",
    isSelected: false,
    icon: "/assets/Icons/Dashboard/red.svg",
    number: "0",
    color: "var(--low)",
  },
  {
    serverKey: "Disabled",
    topic: "Disabled",
    filterKey: "not_working",
    isSelected: false,
    icon: "/assets/Icons/Dashboard/dark-grey.svg",
    number: "0",
    color: "var(--not-working)",
  },
  {
    serverKey: "Maintenance",
    topic: "Maintenance",
    isSelected: false,
    icon: "/assets/Icons/Dashboard/blue.svg",
    number: "0",
    color: "var(--construc)",
  },
  {
    serverKey: "No_information",
    topic: "No_information",
    filterKey: "no_info",
    isSelected: false,
    icon: "/assets/Icons/Dashboard/light-grey.svg",
    number: "0",
    color: "var(--no-info)",
  },
];

export default initialTitles;
