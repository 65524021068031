import { createSlice } from "@reduxjs/toolkit";
import addIfNew from "../../Services/Arrays";

const initialEventsState = {
  events: [],
  eventsCopy: [],
  textFilters: [
    { name: "one_platforms", key: "machineID", text: "" },
    { name: "techName", key: "techName", text: "" },
  ],
  dateSelect: {
    options: [
      {
        key: "last_week",
        value: () => {
          const now = new Date();
          return new Date(
            now.getFullYear(),
            now.getMonth(),
            now.getDate() - 7
          ).getTime();
        },
      },
      {
        key: "last_month",
        value: () => {
          var x = new Date();
          x.setDate(1);
          x.setMonth(x.getMonth() - 1);
          return x.getTime();
        },
      },
      {
        key: "last_quarter",
        value: () => {
          var x = new Date();
          x.setDate(1);
          x.setMonth(x.getMonth() - 4);
          return x.getTime();
        },
      },
      {
        key: "all",
        value: () => {
          return 0;
        },
      },
    ],
    selected: {
      key: "all",
      value: () => {
        return 0;
      },
    },
  },
  selectFilters: [
    { type: "select", name: "type", key: "type", options: [], selected: "All" },
    {
      type: "select",
      name: "severity",
      key: "ScoreTitle",
      options: [],
      selected: "All",
    },
    {
      type: "select",
      name: "source",
      key: "source",
      options: [],
      selected: "All",
    },
  ],
};

const eventsSlice = createSlice({
  name: "events",
  initialState: initialEventsState,
  reducers: {
    setOptions(state, action) {
      state.events = action.payload;
      state.eventsCopy = action.payload;
      action.payload.map((event) => {
        state.selectFilters.map((filter) => {
          addIfNew(filter.options, event.EventDetails[filter.key]);
        });
      });
    },
    dateSelected(state, action) {
      const date = state.dateSelect.options.filter((op) => {
        return op.key === action.payload.selected;
      });
      state.dateSelect.selected = { ...date[0] };
    },
    FilterBySelect(state, action) {
      state.selectFilters.filter((filter) => {
        if (
          filter.type === action.payload.type &&
          filter.name === action.payload.name
        ) {
          filter.selected = action.payload.selected;
        }
      });
      state.events = state.eventsCopy.map((event) => {
        const filtered = state.selectFilters.filter((filter) => {
          if (filter.selected === "All") {
            return true;
          }
          return event.EventDetails[filter.key] === filter.selected;
        });
        const filtered2 = state.textFilters.filter((filter) => {
          return String(event.EventDetails[filter.key]).includes(filter.text);
        });
        if (
          filtered.length >= state.selectFilters.length &&
          filtered2.length >= state.textFilters.length
        ) {
          return event;
        } else {
          return null;
        }
      });
    },
    FilterByText(state, action) {
      state.textFilters = state.textFilters.map((filter) => {
        if (filter.key === action.payload.key) {
          filter.text = String(action.payload.value).toLowerCase();
        }
        return filter;
      });
      state.events = state.eventsCopy.map((event) => {
        const filtered = state.textFilters.filter((filter) => {
          return String(event.EventDetails[filter.key])
            .toLowerCase()
            .includes(filter.text);
        });
        const filtered2 = state.selectFilters.filter((filter) => {
          if (filter.selected === "All") {
            return true;
          }
          return event.EventDetails[filter.key] === filter.selected;
        });
        if (
          filtered.length >= state.textFilters.length &&
          filtered2.length >= state.selectFilters.length
        ) {
          return event;
        } else {
          return null;
        }
      });
    },
    ResetOptions(state, action) {
      state.selectFilters = state.selectFilters.map((filter) => {
        filter.selected = "All";
        return filter;
      });
      state.textFilters = state.textFilters.map((filter) => {
        filter.text = "";
        return filter;
      });
      state.events = state.eventsCopy;
    },
  },
});

export const eventsActions = eventsSlice.actions;

export default eventsSlice.reducer;
