import { createSlice } from "@reduxjs/toolkit";
import addIfNew from "../../Services/Arrays";

const initialPlatformsState = {
  platforms: [],
  platformsCopy: [],
  areaOptions: [],
  areaSelected: "All",
  unitOptions: [],
  unitSelected: "All",
  scoreOptions: [],
  scoreSelected: "All",
  dashboard: { filter: false, score: null },
};

const platformsSlice = createSlice({
  name: "platforms",
  initialState: initialPlatformsState,
  reducers: {
    setDashboard(state, action) {
      state.dashboard.score = action.payload;
      state.dashboard.filter = true;
    },
    setPlatforms(state, action) {
      state.platforms = action.payload;
      state.platformsCopy = action.payload;
      action.payload.map((plat) => {
        addIfNew(state.areaOptions, plat.AreaID);
        addIfNew(state.scoreOptions, plat.ScoreTitle);
      });
    },
    setUnitOptions(state, action) {
      state.unitOptions = [];
      if (action.payload !== "All") {
        state.platforms.map((plat) => {
          addIfNew(state.unitOptions, plat.UnitID);
        });
      }
    },
    SearchById(state, action) {
      state.platforms = state.platformsCopy.filter((plat) => {
        let areaFlag = true;
        let unitFlag = true;
        let scoreFlag = true;
        if (plat.MachineID.includes(action.payload)) {
          if (state.areaSelected !== "All")
            areaFlag = plat.AreaID === state.areaSelected;
          if (state.unitSelected !== "All")
            unitFlag = plat.UnitID === state.unitSelected;
          if (state.scoreSelected !== "All")
            scoreFlag = plat.ScoreTitle === state.scoreSelected;
          return scoreFlag && areaFlag && unitFlag;
        }
      });
    },
    SearchByArea(state, action) {
      state.areaSelected = action.payload;
      state.unitSelected = "All";
      if (
        [action.payload, state.unitSelected, state.scoreSelecte].every(
          (val) => val === "All"
        )
      ) {
        state.platforms = state.platformsCopy;
      } else {
        state.platforms = state.platformsCopy.filter((plat) => {
          let unitFlag = true;
          let scoreFlag = true;
          if (plat.AreaID === action.payload || action.payload === "All") {
            if (state.unitSelected !== "All")
              unitFlag = plat.UnitID === state.unitSelected;
            if (state.scoreSelected !== "All")
              scoreFlag = plat.ScoreTitle === state.scoreSelected;
            return scoreFlag && unitFlag;
          }
        });
      }
    },
    SearchByUnit(state, action) {
      state.unitSelected = action.payload;
      if (
        [action.payload, state.areaSelected, state.scoreSelecte].every(
          (val) => val === "All"
        )
      ) {
        state.platforms = state.platformsCopy;
      } else {
        state.platforms = state.platformsCopy.filter((plat) => {
          let areaFlag = true;
          let scoreFlag = true;
          if (plat.UnitID === action.payload || action.payload === "All") {
            if (state.areaSelected !== "All")
              areaFlag = plat.AreaID === state.areaSelected;
            if (state.scoreSelected !== "All")
              scoreFlag = plat.ScoreTitle === state.scoreSelected;
            return scoreFlag && areaFlag;
          }
        });
      }
    },
    SearchByScore(state, action) {
      state.scoreSelected = action.payload;
      if (
        [action.payload, state.unitSelected, state.areaSelected].every(
          (val) => val === "All"
        )
      ) {
        state.platforms = state.platformsCopy;
      } else {
        state.platforms = state.platformsCopy.filter((plat) => {
          let areaFlag = true;
          let unitFlag = true;
          if (plat.ScoreTitle === action.payload || action.payload === "All") {
            if (state.areaSelected !== "All")
              areaFlag = plat.AreaID.includes(state.areaSelected);
            if (state.unitSelected !== "All")
              unitFlag = plat.UnitID.includes(state.unitSelected);
            return areaFlag && unitFlag;
          }
        });
      }
      state.dashboard.filter = false;
    },
    ResetOptions(state, action) {
      state.areaSelected = "All";
      state.unitSelected = "All";
      state.scoreSelected = "All";
      state.platforms = state.platformsCopy;
    },
  },
});

export const platformsActions = platformsSlice.actions;

export default platformsSlice.reducer;
