import React from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core";
import { Typography, Skeleton } from "../..";

const useStyles = makeStyles(() => ({
  container: (props) => ({
    width: "77px",
    height: "38px",
    padding: "8px, 16px",
    position: "relative",
    display: "flex",
    alignItems: "center",
    borderRadius: "4px",
    justifyContent: "center",
  }),
  overlay: (props) => ({
    position: "absolute",
    backgroundColor: "var(--part-status-bg-color)",
    borderRadius: "4px",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    opacity: "0.2",
  }),
}));

const EventType = (props) => {
  const { t } = useTranslation();
  const classes = useStyles({ type: props.type.toLowerCase() });

  return (
    <div className={classes.container}>
      <div className={classes.overlay}></div>
      <Typography variant="16" style={{ color: "var(--unnamed-color-01046b)" }}>
        {t(props.type.toLowerCase())}
      </Typography>
    </div>
  );
};

export default EventType;
