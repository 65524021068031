import { createSlice } from "@reduxjs/toolkit";
import cookies from "js-cookie";

const initialSettingsState = {
  language: {
    documentDir: document.body.dir,
    text: "",
    code: cookies.get("i18next" || "he"),
  },
};

const settingsSlice = createSlice({
  name: "settings",
  initialState: initialSettingsState,
  reducers: {
    toggleLanguage(state, action) {
      state.language.code = action.payload.code;
      state.language.documentDir = action.payload.dir;
    },
  },
});

export const settingsActions = settingsSlice.actions;

export default settingsSlice.reducer;
