import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import AppContext from "../../../Context/AppContext";
import { Button, makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  continer: {
    position: "absolute",
    right: "17%",
    margin: "14px",
    zIndex: "5",
  },
});

const Logout = (props) => {
  const { logout } = useContext(AppContext);
  const { t } = useTranslation();

  const classes = useStyles();

  return (
    <div className={classes.continer}>
      <Button variant="contained" color="primary" onClick={logout}>
        {t("logout")}
      </Button>
    </div>
  );
};

export default Logout;
