import React from "react";

import { makeStyles } from "@material-ui/core";
import Shimmer from "../Shimmer/Shimmer";

const useStyles = makeStyles({
  skeleton: {
    backgroundColor: "#ddd",
    margin: "10px, 0",
    borderRadius: "4px",
  },
  skeletonWrapper: {
    position: "relative",
  },
  text: (props) => ({
    width: props?.width || "100%",
    height: props?.height || "12px",
  }),
  title: (props) => ({
    width: props?.width || "50%",
    height: props?.height || "20px",
    marginBottom: "15px",
  }),
  avatar: (props) => ({
    width: props?.width || "100px",
    height: props?.height || "100px",
    borderRadius: "50%",
  }),
  tumbnail: (props) => ({
    width: props?.width || "100px",
    height: props?.height || "100px",
  }),
});

const Skeleton = (props) => {
  const classes = useStyles({ width: props?.width, height: props?.height });
  const skelClasses = `${classes.skeleton} ${classes[props.type]}`;

  return (
    <div className={classes.skeletonWrapper}>
      <div className={skelClasses}></div>
      <Shimmer />
    </div>
  );
};

export default Skeleton;
