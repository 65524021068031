import { manageActions } from "./manage";

const initialField = { variant: "outlined", error: false };

const initialState = () => {
  let key = 0;

  return {
    add_options: [
      {
        name: "user",
        data: [
          {
            initialField,
            component: "FormControl",
            props: { key: key++, variant: "outlined" },
            children: [
              {
                component: "InputLabel",
                props: { key: key++ },
                children: "type",
              },
              {
                component: "Select",
                props: { key: key++, defaultValue: "" },
                children: [
                  {
                    component: "MenuItem",
                    props: { key: key++, value: "app" },
                    children: "app",
                  },
                  {
                    component: "MenuItem",
                    props: { key: key++, value: "web" },
                    children: "web",
                  },
                ],
              },
            ],
          },
          {
            component: "FormControl",
            value: "",
            name: "username",
            props: { key: key++, ...initialField },
            children: [
              {
                component: "InputLabel",
                props: { key: key++ },
                children: "username",
              },
              {
                component: "Input",
                props: {
                  key: key++,
                  name: "username",
                },
              },
              {
                component: "FormHelperText",
                props: { key: key++ },
                children: "",
              },
            ],
          },
          {
            component: "FormControl",
            value: "",
            name: "password",
            props: { key: key++, ...initialField },
            children: [
              {
                component: "InputLabel",
                props: { key: key++ },
                children: "password",
              },
              {
                component: "Input",
                props: {
                  key: key++,
                  name: "password",
                },
              },
              {
                component: "FormHelperText",
                props: { key: key++ },
                children: "",
              },
            ],
          },
          {
            component: "FormControl",
            value: "",
            name: "role",
            props: { key: key++, ...initialField },
            children: [
              {
                component: "InputLabel",
                props: { key: key++ },
                children: "role",
              },
              {
                component: "Input",
                props: {
                  key: key++,
                  name: "role",
                },
              },
              {
                component: "FormHelperText",
                props: { key: key++ },
                children: "",
              },
            ],
          },
          {
            component: "FormControl",
            value: "",
            name: "phoneNumber",
            props: { key: key++, ...initialField },
            children: [
              {
                component: "InputLabel",
                props: { key: key++ },
                children: "phoneNumber",
              },
              {
                component: "Input",
                props: {
                  key: key++,
                  name: "phoneNumber",
                },
              },
              {
                component: "FormHelperText",
                props: { key: key++ },
                children: "",
              },
            ],
          },
          {
            component: "FormControl",
            value: "",
            name: "email",
            props: { key: key++, ...initialField },
            children: [
              {
                component: "InputLabel",
                props: { key: key++ },
                children: "email",
              },
              {
                component: "Input",
                props: {
                  key: key++,
                  name: "email",
                },
              },
              {
                component: "FormHelperText",
                props: { key: key++ },
                children: "",
              },
            ],
          },
          ,
        ],
        isFormValid: false,
      },
      { name: "one_platforms" },
      { name: "one_events" },
      { name: "one_units" },
      { name: "one_areas" },
    ],
    modal: { open: false, data: null },
  };
};
export default initialState;
