import React from "react";
import { useSelector } from "react-redux";
import { SideBar } from "../../../Components/Navigation";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  app: (props) => ({
    minHeight: "90vh",
    display: "flex",
    position: "relative",
    fontFamily:
      props.code === "en"
        ? "var(--unnamed-font-family-noto-sans-english)"
        : "var(--unnamed-font-family-noto-sans-hebrew)",
  }),
  page: (props) => ({
    backgroundColor: "var(--main-bg-color)",
    width: "100%",
    height: "100%",
    paddingTop: "calc(var(--topBar-height) + 16px)",
    paddingRight: props.code === "he" && "calc(var(--drawer-width) - 60px)",
    paddingLeft: props.code === "en" && "calc(var(--drawer-width) - 60px)",
    position: "fixed",
    display: "flex",
    justifyContent: "center",
    overflowY: "scroll",
  }),
});

const Layout = (props) => {
  const language = useSelector((state) => state.settings.language);

  const classes = useStyles({ code: language.code });

  return (
    <div className={classes.app}>
      <SideBar>
        <main className={classes.page}>{props.children}</main>
      </SideBar>
    </div>
  );
};

export default Layout;
