import { createSlice } from "@reduxjs/toolkit";
import initialState from "./initialState";

const manageSlice = createSlice({
  name: "manage",
  initialState: () => initialState(),
  reducers: {
    openModal(state, action) {
      state.add_options = state.add_options.map((op) => {
        if (op.name === action.payload) {
          state.modal.data = op;
          state.modal.open = true;
        }
        return op;
      });
    },
    closeModal(state, action) {
      state.add_options = initialState().add_options;
      state.modal.open = false;
    },
    handleChange(state, action) {
      state.add_options.map((op) => {
        if (state.modal.data.name === op.name) {
          op = op?.data?.map((op) => {
            if (op.name === action.payload.name) {
              op.value = action.payload.value;
              op.children.map((op) => {
                if (op.component === "Select") {
                  op.props.value = action.payload.value;
                }
                return op;
              });
            }
            return op;
          });
        }
        return op;
      });
    },
  },
});

export const manageActions = manageSlice.actions;

export default manageSlice.reducer;
