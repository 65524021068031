import React from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core";
import { Typography, Skeleton } from "../..";

const useStyles = makeStyles({
  container: (props) => ({
    width: "77px",
    height: "38px",
    padding: "8px, 16px",
    backgroundColor:
      props.lvl === "low"
        ? "var(--low)"
        : props.lvl === "mid"
        ? "var(--mid)"
        : props.lvl === "high"
        ? "var(--high)"
        : props.lvl === "no_info"
        ? "var(--no-info)"
        : props.lvl === "not_working"
        ? "var(--not-working)"
        : props.lvl === "maintenance"
        ? "var(--construc)"
        : "",
    display: "flex",
    alignItems: "center",
    borderRadius: "4px",
    justifyContent: "center",
  }),
  topic: {
    color: "var(--white-text-color)",
  },
});

const StatusSnackBar = (props) => {
  const { t } = useTranslation();
  const classes = useStyles({ lvl: props.lvl, event: props.event });

  return (
    <>
      {props.isLoading ? (
        <div style={{ padding: "16px" }}>
          <Skeleton type="tumbnail" width="77px" height="38px" />
        </div>
      ) : (
        <div className={classes.container}>
          <Typography variant="16" className={classes.topic}>
            {props.event
              ? props.event && props.lvl === "low"
                ? t("high")
                : props.event && props.lvl === "high"
                ? t("low")
                : t(props.lvl)
              : t(props.lvl)}
          </Typography>
        </div>
      )}
    </>
  );
};

export default StatusSnackBar;
