import React, { useContext, Suspense } from "react";
import { Layout, ProtectedRoute } from "./UiKit";
import { Route, Routes, Navigate } from "react-router-dom";
import AppContext from "./Context/AppContext";

const Login = React.lazy(() => import("./Pages/Login"));

const Dashboard = React.lazy(() => import("./Pages/DashBoard"));
const Platforms = React.lazy(() => import("./Pages/Platforms"));
const Platform = React.lazy(() => import("./Pages/Platform"));
const Units = React.lazy(() => import("./Pages/Units"));
const Unit = React.lazy(() => import("./Pages/Unit"));
const Areas = React.lazy(() => import("./Pages/Areas"));
const Area = React.lazy(() => import("./Pages/Area"));
const Events = React.lazy(() => import("./Pages/Events"));
const Manage = React.lazy(() => import("./Pages/Manage"));

const NotFound = React.lazy(() => import("./Pages/NotFound"));

const App = () => {
  const { user } = useContext(AppContext);
  return (
    <Layout>
      <Suspense
        fallback={
          <img src="/assets/Icons/Loading/loading.svg" width="20%" height="50%"></img>
        }
      >
        <Routes>
          <Route
            path="/"
            element={user === null ? <Login /> : <Navigate to="dashboard" />}
          />
          <Route element={<ProtectedRoute />}>
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/platforms" element={<Platforms />} />
            <Route path="/platforms/:id" element={<Platform />} />
            <Route path="/units" element={<Units />} />
            <Route path="/units/:id" element={<Unit />} />
            <Route path="/areas" element={<Areas />} />
            <Route path="/areas/:id" element={<Area />} />
            <Route path="/notifications" element={<Events />} />
            <Route path="/manage" element={<Manage />} />
          </Route>
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Suspense>
    </Layout>
  );
};

export default App;
