import { createSlice } from "@reduxjs/toolkit";

const initialNavigationState = {
  navOptions: [
    {
      route: "dashboard",
      icon: "/assets/Icons/Navigition/SideBar/dashboard.svg",
    },
    {
      route: "areas",
      search: "area",
      icon: "/assets/Icons/Navigition/SideBar/areas.svg",
    },
    {
      route: "units",
      search: "unit",
      icon: "/assets/Icons/Navigition/SideBar/units.svg",
    },
    {
      route: "platforms",
      search: "platform",
      icon: "/assets/Icons/Navigition/SideBar/platforms.svg",
    },
    {
      route: "notifications",
      icon: "/assets/Icons/Navigition/SideBar/notifications.svg",
    },
    {
      route: "manage",
      icon: "/assets/Icons/Navigition/SideBar/manage.svg",
    },
  ],
  currentRoute: {
    route: null,
    id: null,
    icon: "/assets/Icons/Navigition/SideBar/dashboard.svg",
  },
};

const navigationSlice = createSlice({
  name: "navigation",
  initialState: initialNavigationState,
  reducers: {
    setRefreshRoute(state, action) {
      state.navOptions.map((op) => {
        if (op.route === action.payload.route) {
          state.currentRoute = op;
          state.currentRoute.id = action.payload.id;
          return;
        }
      });
    },
    setCurrentRoute(state, action) {
      state.currentRoute = action.payload;
    },
    setCurrentRouteId(state, action) {
      state.currentRoute.id = action.payload;
    },
  },
});

export const navigationActions = navigationSlice.actions;

export default navigationSlice.reducer;
