import { createSlice } from "@reduxjs/toolkit";
import addIfNew from "../../Services/Arrays";

const initialUnitsState = {
  units: [],
  unitsCopy: [],
  areaOptions: [],
  areaSelected: "All",
  scoreOptions: [],
  scoreSelected: "All",
  dashboard: { filter: false, score: null },
};

const UnitsSlice = createSlice({
  name: "units",
  initialState: initialUnitsState,
  reducers: {
    setDashboard(state, action) {
      state.dashboard.score = action.payload;
      state.dashboard.filter = true;
    },
    setUnits(state, action) {
      state.units = action.payload;
      state.unitsCopy = action.payload;
      action.payload.map((unit) => {
        addIfNew(state.areaOptions, unit.AreaID);
        addIfNew(state.scoreOptions, unit.ScoreTitle);
      });
    },
    SearchById(state, action) {
      state.units = state.unitsCopy.filter((unit) => {
        let areaFlag = true;
        let scoreFlag = true;
        if (unit.UnitID.includes(action.payload)) {
          if (state.areaSelected !== "All")
            areaFlag = unit.AreaID === state.areaSelected;
          if (state.scoreSelected !== "All")
            scoreFlag = unit.ScoreTitle === state.scoreSelected;
          return scoreFlag && areaFlag;
        }
      });
    },
    SearchByArea(state, action) {
      state.areaSelected = action.payload;
      if ([action.payload, state.scoreSelecte].every((val) => val === "All")) {
        state.units = state.unitsCopy;
      } else {
        state.units = state.unitsCopy.filter((unit) => {
          let scoreFlag = true;
          if (unit.AreaID === action.payload || action.payload === "All") {
            if (state.scoreSelected !== "All")
              scoreFlag = unit.ScoreTitle === state.scoreSelected;
            return scoreFlag;
          }
        });
      }
    },
    SearchByScore(state, action) {
      state.scoreSelected = action.payload;
      if ([action.payload, state.areaSelected].every((val) => val === "All")) {
        state.units = state.unitsCopy;
      } else {
        state.units = state.unitsCopy.filter((unit) => {
          let areaFlag = true;
          if (unit.ScoreTitle === action.payload || action.payload === "All") {
            if (state.areaSelected !== "All")
              areaFlag = unit.AreaID.includes(state.areaSelected);
            return areaFlag;
          }
        });
      }
      state.dashboard.filter = false;
    },
    ResetOptions(state, action) {
      state.areaSelected = "All";
      state.scoreSelected = "All";
      state.units = state.unitsCopy;
    },
  },
});

export const unitsActions = UnitsSlice.actions;

export default UnitsSlice.reducer;
