import initialTitles from "../../Constants/initialTitles";
import calcScore from "../../Services/Score";
import { createSlice } from "@reduxjs/toolkit";

const initialAreaState = {
  titleOptions: initialTitles,
  platforms: [],
  platformsCopy: [],
  filters: [],
  searchId: "",
  score: null,
  isLoading: false,
};

const areaSlice = createSlice({
  name: "area",
  initialState: initialAreaState,
  reducers: {
    setTitleOptionsNum(state, action) {
      state.titleOptions.map((titleOption) => {
        titleOption.number = action.payload[titleOption.serverKey];
      });
    },
    setScore(state, action) {
      state.score = action.payload;
    },
    setLoading(state, action) {
      state.isLoading = action.payload;
    },
    setPlatforms(state, action) {
      state.platforms = action.payload;
      state.platformsCopy = action.payload;
    },
    SearchById(state, action) {
      state.searchId = action.payload;
      if (state.filters.length > 0) {
        state.platforms = state.platformsCopy.map((platform) => {
          const filtered = state.filters.filter((filter) => {
            if (String(filter).toLowerCase() === "medium") {
              return (
                calcScore(platform.Score).toLowerCase() ===
                  String("mid").toLowerCase() &&
                platform.MachineID.includes(action.payload)
              );
            }
            return (
              calcScore(platform.Score).toLowerCase() ===
                String(filter).toLowerCase() &&
              platform.MachineID.includes(action.payload)
            );
          });
          if (filtered.length > 0) {
            return platform;
          }
        });
      } else {
        state.platforms = state.platformsCopy.filter((platform) => {
          let scoreFlag = true;
          if (platform.MachineID.includes(action.payload)) {
            return scoreFlag;
          }
        });
      }
    },
    titleClicked(state, action) {
      let flag;
      state.titleOptions.forEach((element, index) => {
        if (
          element.serverKey === action.payload ||
          element.filterKey === action.payload
        ) {
          flag = !state.titleOptions[index].isSelected;
          state.titleOptions[index] = {
            ...state.titleOptions[index],
            isSelected: !state.titleOptions[index].isSelected,
          };
        }
      });
      if (flag) {
        state.filters = [...state.filters, action.payload]; //add filter
        state.platforms = state.platformsCopy.map((platform) => {
          const filtered = state.filters.filter((filter) => {
            if (String(filter).toLowerCase() === "medium") {
              return (
                calcScore(platform.Score).toLowerCase() ===
                  String("mid").toLowerCase() &&
                platform.MachineID.includes(state.searchId)
              );
            }
            return (
              calcScore(platform.Score).toLowerCase() ===
                String(filter).toLowerCase() &&
              platform.MachineID.includes(state.searchId)
            );
          });
          if (filtered.length > 0) {
            return platform;
          }
        });
      } else {
        const index = state.filters.indexOf(action.payload);
        if (index > -1) {
          //remove filter
          state.filters.splice(index, 1);
        }
        if (state.filters.length > 0) {
          state.platforms = state.platformsCopy.map((platform) => {
            const filtered = state.filters.filter((filter) => {
              if (String(filter).toLowerCase() === "medium") {
                return (
                  calcScore(platform.Score).toLowerCase() ===
                    String("mid").toLowerCase() &&
                  platform.MachineID.includes(state.searchId)
                );
              }
              return (
                calcScore(platform.Score).toLowerCase() ===
                  String(filter).toLowerCase() &&
                platform.MachineID.includes(state.searchId)
              );
            });
            if (filtered.length > 0) {
              return platform;
            }
          });
        } else {
          state.platforms = state.platformsCopy.map((platform) => {
            if (platform.MachineID.includes(state.searchId)) {
              return platform;
            }
          });
        }
      }
    },
  },
});

export const areaActions = areaSlice.actions;

export default areaSlice.reducer;
