import React from "react";
import { makeStyles } from "@material-ui/core";
import CalcScore from "../../../Services/Score/CalcScore";

const useStyles = makeStyles({
  circle: (props) => ({
    fill:
      props.lvl === "low"
        ? "var(--low)"
        : props.lvl === "mid"
        ? "var(--mid)"
        : props.lvl === "high"
        ? "var(--high)"
        : props.lvl === "no_info"
        ? "var(--no-info)"
        : props.lvl === "not_working"
        ? "var(--not-working)"
        : props.lvl === "maintenance"
        ? "var(--construc)"
        : "",
  }),
});

const ScoreCircle = (props) => {
  const classes = useStyles({ lvl: CalcScore(props.lvl) });

  return (
      
    <svg height="16" width="16">
    <circle cx="8" cy="8" r="8" className={classes.circle} />
  </svg>
  );
};

export default ScoreCircle;
