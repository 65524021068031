import { configureStore } from "@reduxjs/toolkit";

import settingsReducer from "./Settings";
import navigationReducer from "./Navigation";
import dashboardReducer from "./Dashboard";
import platformsReducer from "./Platforms";
import platformReducer from "./Platform";
import unitsReducer from "./Units";
import unitReducer from "./Unit";
import areasReducer from "./Areas";
import areaReducer from "./Area";
import eventsReducer from "./Events";
import manageReducer from "./Manage";

const store = configureStore({
  reducer: {
    settings: settingsReducer,
    navigation: navigationReducer,
    dashboard: dashboardReducer,
    platforms: platformsReducer,
    platform: platformReducer,
    units: unitsReducer,
    unit: unitReducer,
    areas: areasReducer,
    area: areaReducer,
    events: eventsReducer,
    manage: manageReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }),
});

export default store;
