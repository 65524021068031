export default function (score) {
  let scoreTitle;
  switch (true) {
    case score >= 70:
      scoreTitle = "low";
      break;
    case score <= 69 && score >= 40:
      scoreTitle = "mid";
      break;
    case score <= 39 && score >= 10:
      scoreTitle = "high";
      break;
    case score <=9 && score >= 0:
      scoreTitle = "no_info";
      break;
    case score === -1:
      scoreTitle = "not_working";
      break;
    case score === -2:
      scoreTitle = "maintenance";
      break;
  }
  return scoreTitle;
}
