import React, { useState } from "react";
import useStickyState from "../Hooks/LocalStorage";
import LanguageDetector from "../Services/Language";
import { ThemeProvider } from "@material-ui/core/styles";
import appTheme from "../UiKit/appTheme";

const SettingsContext = React.createContext({
  theme: null,
  onThemeSwitch: () => {},
  language: null,
  onLanguageSwitch: () => {},
});

export const SettingsContextProvider = (props) => {
  const [theme, setTheme] = useStickyState(appTheme, "theme");
  const [language, setLanguage] = useStickyState(LanguageDetector, "language");

  return (
    <ThemeProvider theme={appTheme}>
      <SettingsContext.Provider
        value={{
          theme,
          onThemeSwitch: () => {},
          language,
          onLanguageSwitch: () => {},
        }}
      >
        {props.children}
      </SettingsContext.Provider>
    </ThemeProvider>
  );
};

export default SettingsContext;
