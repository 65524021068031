import React from "react";

const MapMarker = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      version="1.1"
      viewBox="0 0 18 18"
    >
      <g data-name="Group 5967">
        <path fill="none" d="M0 0H18V18H0z" data-name="Rectangle 1710"></path>
      </g>
      <path
        fill={
          props.lvl === "low"
            ? "var(--low)"
            : props.lvl === "mid"
            ? "var(--mid)"
            : props.lvl === "high"
            ? "var(--high)"
            : props.lvl === "no_info"
            ? "var(--no-info)"
            : props.lvl === "not_working"
            ? "var(--not-working)"
            : props.lvl === "not_working"
            ? "var(--not-working)"
            : props.lvl === "maintenance"
            ? "var(--construc)"
            : ""
        }
        stroke="#fff"
        strokeDasharray="none"
        strokeMiterlimit="4"
        strokeOpacity="1"
        strokeWidth="1"
        d="M15.042 8.763a6.007 6.007 0 01-6.007 6.007A6.007 6.007 0 013.03 8.763a6.007 6.007 0 016.006-6.006 6.007 6.007 0 016.007 6.006z"
      ></path>
      <path
        fill="#fff"
        fillOpacity="1"
        strokeWidth="0.395"
        d="M7.657 5.732v.552h.553v-.552zm1.102 0v.552h.553v-.552zm1.102 0v.552h.553v-.552zM7.657 6.834a.555.555 0 00-.553.553v2.755a.555.555 0 00.553.553h2.755a.555.555 0 00.553-.553V7.385a.555.555 0 00-.553-.553zm-1.653.55v.553h.553v-.552zm5.51 0v.553h.553v-.552zm-3.03.827h1.102v1.102H8.483zm-2.48.276v.553h.553v-.553zm5.51 0v.553h.553v-.553zm-5.51 1.102v.553h.553v-.553zm5.51 0v.553h.553v-.553zm-3.857 1.653v.553h.553v-.553zm1.102 0v.553h.553v-.553zm1.102 0v.553h.553v-.553z"
      ></path>
    </svg>
  );
};

export default MapMarker;
