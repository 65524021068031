import React from "react";

const Typography = (props) => {
  const classes = props.className;
  const s = {
    fontFamily:
      props.number && "var(--unnamed-font-family-noto-sans-hebrew-number)",
    ...props.style,
  };

  return (
    <p
      className={`unnamed-character-style-${props.variant} ${classes}`}
      style={s}
    >
      {props.children}
    </p>
  );
};

export default Typography;
